export const init = () => {
    const $body = document.querySelector('body');

    if ($body) {
        window.onscroll = () => {
            if (document.documentElement.scrollTop > window.outerHeight / 2 || self.pageYOffset > window.outerHeight / 2) {
                $body.classList.add('page--scrolled');
            } else {
                $body.classList.remove('page--scrolled');
            }
        };
    }
};
