export const init = () => {
    const $newsletterForm = document.querySelector('#page-newsletter');

    if ($newsletterForm) {
        const $newsletterTrigger = $newsletterForm && document.querySelectorAll('.trigger-newsletter');

        Array.prototype.forEach.call($newsletterTrigger, $element =>
            $element.addEventListener('click', () => $newsletterForm.classList.toggle('page-newsletter--active')
            ));
    }
};
